html {
  background-color: $viewport-background-color;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

*:focus {
  outline: none
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0 !important;
}

.underline-word {
  position: relative;
  display: inline-block;
  /* Adjust the font size as needed */
}

.underline-word::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 20px;
  /* Initial height of the underline */
  background-color: blue;
  /* Adjust the underline color */
  bottom: 0;
  transform-origin: left bottom;
  transform: scaleX(0);
  /* Initial scaling to hide the underline */
  transition: transform 0.3s ease-in-out;
}